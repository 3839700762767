import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image'

const Footer = () => {
  
    return (
        <footer className="bg-dark" style={{height:220}}>
            <Container className="bg-dark text-white p-3">
                <Row >
                    <Col className="d-flex justify-content-center align-items-center">
                        <StaticImage 
                            src="../images/logo-dark.png" 
                            alt="" 
                            height={60}
                        /> 
                        <h3 className="ml-2">Colegio Cielos del Valle</h3>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-center align-items-center">
                        Copyright © 2024 - All Rights Reserved - colegiocielosdelvalle.cl
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={5}>
                        Telefono:<a href="tel:+512250675"> +51-2250675</a><br />
                        Direccion: Las Rosas 2872, Las Compañias, La Serena <br />
                        Email: colegio.cielosdelvalle@gmail.com<br />
                    </Col>
                    <Col md={6}>
                        
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer;